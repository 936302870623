import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import styled from "styled-components"

import '../../../assets/sass/slick.scss'

var settings = {
  arrows: false,
  dots: true,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 830,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

class RelatedPosts extends React.Component {
  swiping = false
  carouselRef = React.createRef()

  handleMouseDown = event => {
    event.preventDefault()
  }

  handleMouseUp = () => {
    this.swiping = this.carouselRef.current.innerSlider.state.swiping
  }

  handleClick = event => {
    if (this.swiping) {
      event.preventDefault()
    }
  }

  render() {
    const { items } = this.props

    return (
      <>
        {items.edges.length > 0 && (
          <Wrapper>
            <SectionTitle>Artigos relacionados</SectionTitle>
            <SliderContainer ref={this.carouselRef} {...settings}>
              {items.edges.map(item => (
                <div
                  key={item.node.id}
                  onClickCapture={this.handleClick} onMouseUpCapture={this.handleMouseUp} onMouseDownCapture={this.handleMouseDown}
                >
                  <Link to={item.node.fields.slug}>
                    <Image src={item.node.frontmatter.cover} alt="post picture" />
                  </Link>
                  <Title to={item.node.fields.slug}>{item.node.frontmatter.title}</Title>
                </div>
              ))}
            </SliderContainer>
          </Wrapper>
        )}
      </>
    )
  }
}

export default RelatedPosts

const Wrapper = styled.section`
  padding-bottom: 7rem;
`

const SectionTitle = styled.h4`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
`

const SliderContainer = styled(Slider)`
  max-width: 950px;
  margin: 0 auto;

  .slick-slide > div {
    margin: 0 12px;
  }

  .slick-dots {
    bottom: -50px;
  }
`

const Title = styled(Link)`
  color: #000;
  font-size: 18px;
  font-weight: 800;
  transition: color .15s ease-in-out;

  &:hover {
    color: #3d75f4;
  }

  &:focus {
    outline: none;
  }
`

const Image = styled.img`
  height: 170px;
  width: 100%;
  margin-bottom: 10px;
`

