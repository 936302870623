import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share'
import config from '../../../config'
import './styles.scss'

let shareButtonsStyles = {
  iconBgStyle: {
    fill: '#fff'
  },
  logoFillColor: '#283445'
}

class Share extends React.Component {
  render () {
    const { title, slug, excerpt } = this.props
    const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
    const url = config.siteUrl + realPrefix + slug

    return (
      <div className='social-links'>
        <FacebookShareButton url={url} quote={excerpt}>
          <FacebookIcon {...shareButtonsStyles} />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon {...shareButtonsStyles} />
        </TwitterShareButton>
        <LinkedinShareButton url={url}>
          <LinkedinIcon {...shareButtonsStyles} />
        </LinkedinShareButton>
      </div>
    )
  }
}

export default Share
