import styled from 'styled-components'

export const Box = styled.div`
  padding: 50px 20px 30px;
  max-width: 750px;
  margin: 0 auto;

	background-color: #FCFCFC;
	box-shadow: -3px 3px 0 0 rgba(45,80,244,0.5), 3px -3px 0 0 #CEDEFA;

  .input {
    height: 48px;
  }

  .button {
    font-size: 18px;
    height: 48px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto 1.5rem;
  text-align: center;

  @media(min-width: 770px) {
    flex-direction: row;
    justify-content:center;
    margin: 0 auto 62px;
    max-width: 579px;
    text-align: left;
  }
`

export const FileImage = styled.img`
  width: 130px;

  @media(min-width: 770px) {
    margin-right: 30px;
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(min-width: 770px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Title = styled.h4`
  font-size: 30px;
  font-weight: 800;
  line-height: 38px;
  margin: 1rem auto;

  @media(min-width: 770px) {
    margin: 0 0 10px;
  }
`

export const Fields = styled.div`
  display: flex;
  align-items: center;
`
