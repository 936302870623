import styled from 'styled-components'

export const Hero = styled.div`
  position: relative;
  overflow: hidden;
  height: 310px;

  @media(min-width: 800px) {
    height: 350px;
  }

  @media(min-width: 1024px) {
    height: 400px;
    margin-top: 90px;
  }
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const Content = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
	width: 95%;
	border-radius: 4px 4px 0 0;
	background-color: rgba(255,255,255,0.9);
	box-shadow: 0 2px 24px 0 rgba(0,0,0,0.5);
  padding: 46px 15px 21px;
  transform: translateX(-50%);

  @media(min-width: 600px) {
    padding: 46px 60px 21px;
  }

  @media(min-width: 800px) {
    max-width: 750px;
    padding: 46px 80px 21px;
  }

  @media(min-width: 1024px) {
    padding: 46px 85px 21px;
  }
`

export const Tag = styled.span`
  display: block;
  color: ${({ color }) => color};
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 5px;
`

export const Title = styled.h1`
  font-size: 25px !important;
  font-weight: 800;
  line-height: 1.35;
  margin-bottom: 23px;

  @media(min-width: 500px) {
    font-size: 30px !important;
  }

  @media(min-width: 800px) {
    font-size: 40px !important;
    margin: 0 auto 23px;
  }

  @media(min-width: 1024px) {
    font-size: 41px !important;
  }
`

export const DateFormat = styled.span`
  display: block;
  color: #283445;
  font-size: 13px;
  line-height: 21px;
`

export const SectionAfterPost = styled.section`
  padding: 0 1.5rem;
`

export const Separator = styled.hr`
  height: 1px;
	width: 750px;
  border: 1px solid #EBEBEB;
  margin: 70px auto 0;
`

export const StyledSection = styled.section`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 2.5rem;

  display: flex;
  flex-direction: column-reverse;

  @media(min-width: 890px) {
    flex-direction: column;
  }

  p {
    font-size: 16px;
    line-height: 29px;
  }

  h2 {
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 1.5rem;
  }

  ol, ul {
    margin-left: 1rem;
  }

  ol > li {
    list-style: none;
    position: relative;

    &::before {
      counter-increment: itemCounter;
      content: counter(itemCounter) ") ";
      position: absolute;
      left: -1rem;
    }
  }

  li {
    margin-bottom: 1.5rem;
    line-height: 29px;
  }

  img {
    margin: 50px auto;
  }
`
