import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ArticlePageTemplate from '../components/ArticlePageTemplate'
import SE0 from '../components/SEO'
import Layout from '../components/Layout'
import get from 'lodash/get'

import { removeEmptyProperties } from '../utils/helpers'

const ArticlePage = ({ data, location }) => {
  const { post, relatedPosts } = data

  return (
    <Layout location={location}>
      <SE0
        title={post.frontmatter.title}
        meta_title={post.frontmatter.meta.meta_title}
        meta_desc={post.frontmatter.meta.meta_description}
        cover={post.frontmatter.cover}
        slug={post.fields.slug}
      />
      <ArticlePageTemplate
        slug={post.fields.slug}
        cover={post.frontmatter.cover}
        title={post.frontmatter.title}
        category={post.frontmatter.category}
        content={post.html}
        fileDownload={removeEmptyProperties(post.frontmatter.fileDownload)}
        date={post.frontmatter.date}
        timeToRead={post.timeToRead}
        meta={post.frontmatter.meta}
        relatedPosts={relatedPosts}
        cta={removeEmptyProperties(post.frontmatter.cta)}
        author={get(post, 'fields.author.frontmatter')}
      />
    </Layout>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticleByID($id: String!, $category: String) {
    post: markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        cover
        meta {
          meta_title
          meta_description
        }
        category
        fileDownload {
          title
          description
          image
          successMessage
          file
          name_input
          email_input
          terms_input
          button
          validation_msg
        }
        cta {
          heading
          lead
          button {
            url
            text
          }
        }
      }
      fields {
        author {
          frontmatter {
            name
            avatar
            bio
          }
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        id: { ne: $id }
        frontmatter: {
          templateKey: { eq: "article-page" }
          category: { eq: $category }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            cover
          }
        }
      }
    }
  }
`
