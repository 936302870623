import styled, { css } from 'styled-components'
import Markdown from 'react-markdown'

// import { media } from 'utils'
// import checkIcon from 'static/img/check_icon.svg'

export default styled(Markdown)`
  text-align: left;
  width: 100%;

  * {
    /* color: ${({ theme, fontColor }) => theme.colors[fontColor] || fontColor || theme.colors.blueDark}; */
    /* margin: ${({ deleteMargin }) => (deleteMargin ? '0' : 'initial')}; */
  }
  p {
    margin-bottom: 2rem;
  }
  a {
    font-weight: normal;
    border-bottom: 2px solid red;
    transition: border-color .2s;

    &:hover {
      border-bottom-color: transparent;
    }
  }
  strong {
    font-weight: 500;
  }
  img {
    display: block;
    margin: auto;
  }

  ul,
  ol {
    margin-left: 0;
    padding-left: 16px;
    @media (min-width: 576px) {
      margin-left: 1.55rem;
      padding-left: 0;
    }
    @media (min-width: 992px) {
      margin-left: 75px;
    }
  }
  ul > li {
    /* ${getListStyle} */
  }
  span.separator {
    display: block !important;
    height: 3px !important;
  }

  span.spacer {
    display: block !important;
    height: 10px !important;
  }

  @media (min-width: 576px) {
    text-align: left;
    span.separator {
      display: inline-block !important;
      height: 0;
    }
  }
`

// function getListStyle({ listStyle }) {
//   if (listStyle === 'check' || !listStyle) {
//     return css`
//       list-style: none;
//       &:before {
//         content: '';
//         display: inline-block;
//         height: 8px;
//         width: 10px;
//         margin-right: 8px;
//         ${'' /* background-image: url(${checkIcon}); */}
//         background-size: 10px;
//         background-repeat: no-repeat;
//         background-position: left center;
//       }
//     `
//   } else {
//     return css`
//       list-style: ${listStyle};
//     `
//   }
// }
