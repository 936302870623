import React from 'react'
import styled from 'styled-components'
import { Separator } from '../../../components/ArticlePageTemplate/styles'

const Author = ({ name, bio, avatar }) => (
  <section className='section'>
    <div className='container has-text-centered'>
      <Image src={avatar} />
      <Name>{name}</Name>
      <Bio>
        {bio}
      </Bio>
      <Separator />
    </div>
  </section>
)

export default Author

const Image = styled.img`
  border-radius: 50%;
  margin: 0 auto;
  width: 120px;
  height: 120px;
`

const Name = styled.h4`
  color: #4D87ED;
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  margin: 15px 0;
  text-align: center;
`

const Bio = styled.p`
  margin: 0 auto;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  max-width: 620px;
`
