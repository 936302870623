import styled from 'styled-components'

export const ShareWrapper = styled.div`
  svg {
    width: 46px;
    height: 46px;
  }

  .social-links {
    margin: 0;

    .SocialMediaShareButton {
      cursor: pointer;
      height: 46px;
      width: 46px;
      border: 1px solid #F6F6F6;

      &:focus {
        outline: none;
      }

      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  @media(min-width: 890px) {
    position: absolute;
    left: -50px;
    top: 3rem;
    flex-direction: column;
    margin: 0;

    .social-links {
      flex-direction: column;

      .SocialMediaShareButton {
        margin: 0 0 -1px 0;
      }
    }

    svg {
      width: 80%;
      height: 80%;
    }
  }
`
