import React from 'react'
import isEmpty from 'lodash.isempty'
import Share from '../Share'
import { graphql, StaticQuery } from 'gatsby'
import { ShareWrapper } from '../Share/styles'
import StyledMarkdown from '../Common/StyledMarkdown'
import CATEGORIES from '../../utils/categories'
import FileDownload from '../FileDownload'
import Author from '../../partials/blog/Author'
import RelatedPosts from '../../partials/blog/RelatedPosts'
import {
  StyledSection,
  SectionAfterPost,
  Hero,
  Tag,
  Title,
  DateFormat,
  Content,
  Image,
} from './styles'

const ArticlePageTemplate = ({
  slug,
  cover,
  title,
  meta,
  category,
  content,
  fileDownload,
  date,
  timeToRead,
  relatedPosts,
  cta,
  author,
  categories,
}) => {
  const categoryRef = category && CATEGORIES[category]
  const categoryColor = categoryRef ? categoryRef.color : 'transparent'

  return (
    <>
      <Hero>
        <Image src={cover} alt={title} />
        <Content className='has-text-centered'>
          <Tag color={categoryColor}>{categories[category]}</Tag>
          <Title>{title}</Title>
          <DateFormat>
            {date}・{`${timeToRead} min read`}
          </DateFormat>
        </Content>
      </Hero>
      <StyledSection className='section'>
        <ShareWrapper>
          <Share title={title} slug={slug} excerpt={meta.meta_description} />
        </ShareWrapper>
        <StyledMarkdown source={content} escapeHtml={false} />
      </StyledSection>
      <SectionAfterPost>
        {!isEmpty(fileDownload) && fileDownload.file && <FileDownload data={fileDownload} />}
        {author && <Author {...author} />}
        <RelatedPosts items={relatedPosts} />
      </SectionAfterPost>
      {!isEmpty(cta) && (
        <div className='hp-banner2'>
          <div className='container'>
            <div className='columns'>
              <div className='column'>
                <h2>{cta.heading}</h2>
                <h3>{cta.lead}</h3>
              </div>
              {cta.button && (
                <div className='column trialelement'>
                  <a className='button free-trial-btn2' href={cta.button.url}>
                    {cta.button.text}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        categories: markdownRemark(fileAbsolutePath: { regex: "/header-blog/" }) {
          frontmatter {
            categories {
              translations {
                software
                industria
                gestao
                fiscal_e_legal
              }
            }
          }
        }
      }
    `}
    render={data => (
      <ArticlePageTemplate
        categories={data.categories.frontmatter.categories.translations}
        {...props}
      />
    )}
  />
)
