import React from 'react'
import { validateEmail } from '../../utils/validation'
import Markdown from 'react-markdown'

import { Box, Info, FileImage, Text, Title } from './styles'
import { Separator } from '../ArticlePageTemplate/styles'

class FileDownload extends React.Component {
  state = {
    name: '',
    email: '',
    terms: false,
    showValidationMessage: false,
    submitPressed: false,
  }

  handleChange = e => {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
      showValidationMessage: this.renderValidationMessage(),
    })
  }

  _isValidForm = () => {
    const { name, email, terms } = this.state
    return name && terms && validateEmail(email)
  }

  _handleSubmit = (e, file) => {
    e.preventDefault()
    this.setState({ submitPressed: true })

    if (this._isValidForm()) {
      console.log('Form submitted', this.state)

      this.downloadLink.href = file
      this.downloadLink.click()

      this.setState({
        name: '',
        email: '',
        terms: false,
        submitPressed: false,
      })
    } else {
      this.setState({ showValidationMessage: true })
    }
  }

  renderValidationMessage = () => {
    const { submitPressed, showValidationMessage } = this.state
    return !this._isValidForm() && submitPressed && showValidationMessage
  }

  toggleDangerClass = field => {
    return !field && this.state.submitPressed ? 'is-danger' : ''
  }

  render () {
    const { name, email, terms } = this.state
    const { title, description, image, file, name_input, email_input, terms_input, button, validation_msg } = this.props.data

    return (
      <>
        <Box>
          <Info>
            {image && <FileImage src={image} alt='file icon' />}
            <Text>
              <Title>{title}</Title>
              <p>{description}</p>
            </Text>
          </Info>
          <form onSubmit={e => this._handleSubmit(e, file)}>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <div className='field'>
                  <input
                    type='text'
                    name='name'
                    placeholder={name_input}
                    onChange={this.handleChange}
                    value={name}
                    className={`input ${this.toggleDangerClass(name)}`}
                  />
                </div>
                <div className='field'>
                  <input
                    type='email'
                    name='email'
                    placeholder={email_input}
                    onChange={this.handleChange}
                    value={email}
                    className={`input ${this.toggleDangerClass(email)}`}
                  />
                </div>
                <button type='submit' className='button is-primary'>{button}</button>
                <a ref={el => this.downloadLink = el} hidden download>download link</a>
              </div>
            </div>
            <div className='TermsAndConditions'>
              <input type='checkbox' id='terms-footer' name='terms' checked={terms} onChange={this.handleChange} />
              <label htmlFor='terms-footer' className='TermsLabel'>{terms_input}</label>
            </div>

            {this.renderValidationMessage() && (
              <Markdown
                style={{
                  color: 'red',
                  fontSize: 12,
                  marginTop: 10,
                }}
                source={validation_msg}
              />
            )}
          </form>
        </Box>
        <Separator />
      </>
    )
  }
}

export default FileDownload
